export const PAYMENT_METHOD = {
	cash: 1,
	visa: 2,
};

export type PaymentMethodsType =
	(typeof PAYMENT_METHOD)[keyof typeof PAYMENT_METHOD];

export const BOOKING_SCHEDULE = {
	academy: "academy",
	playground: "playground",
};

export type BookingScheduleType =
	(typeof BOOKING_SCHEDULE)[keyof typeof BOOKING_SCHEDULE];
