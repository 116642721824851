import { Injectable, WritableSignal, computed, signal } from "@angular/core";
import { getCookie, setCookie } from "@sportyano/core/common/utils/cookies-controller";

interface IPlayerState {
	id: number;
	is_interest_shown: boolean;
}
interface IApplicationState {
	players: Array<IPlayerState>;
}

const initialState: IApplicationState = {
	players: [],
};

@Injectable({
	providedIn: "root",
})
export class StateManagementService {
	private _stateStore: WritableSignal<IApplicationState> =
		signal<IApplicationState>(initialState);
	readonly stateStore = this._stateStore
	constructor() {}

	public setPlayerState(player: IPlayerState): void {
		this._stateStore.update((s) => {
			return {
				...s,
				players: [...s.players, player],
			};
		});
	}


	public setSessionStorage(key:string,value:unknown){
		sessionStorage.setItem(key,JSON.stringify(value));
	}
	public getSessionStorage(key:string){
		const state = sessionStorage.getItem(key);
		return state? JSON.parse(state) : null;
	}
	public setCookie(key:string,value:unknown){
		setCookie(key,JSON.stringify(value));
	}
	public getCookie(key:string){
		return getCookie(key);
	}
}
